import "./Header.scss";
import Logo from "../../assets/images/logo.svg";
import { useEffect, useRef, useState } from "react";
import SocialMedia from "../../components/SocialMedia";
import useChangeWidth from "../../hooks/useChangeWidth";
import Modal from "../../components/modal/Modal";

const Button = ({ children, className, onClick = null }) => {
  return (
    <>
      <button
        className={`${className} sm:w-[156px] xl:h-[55px] w-full h-[45px] rounded-full border-2 border-white/40 text-xs font-heading relative overflow-hidden hover-eff after:inset-0 after:-z-10 z-10`}
        onClick={onClick}
      >
        {children}
      </button>
    </>
  );
};

const MenuItem = ({ children, scrollY, href, clickHandle }) => {
  return (
    <a
      className={`${
        !scrollY ? "xl:text-xs" : "2xl:text-base xl:text-xs"
      } relative cursor-pointer menu-item hover-eff text-[38px] leading-10`}
      href={`#${href}`}
      onClick={clickHandle}
    >
      {children}
    </a>
  );
};

const MenuButton = ({ className, clickHandle, active }) => {
  return (
    <span
      onClick={clickHandle}
      className={`mobile-menu-button ml-5 relative z-10 ${className} ${
        active ? "active" : ""
      }`}
    >
      <span></span>
    </span>
  );
};

const ComingSoonPop = <i className="coming-soon-pop" />;
const Header = () => {
  const [scrollY, setScrollY] = useState(true);
  const scroll = useRef(false);
  const isTablet = useChangeWidth(1280);
  const [activeMenu, setActiveMenu] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(null);
  const [scrollLock, setScrollLock] = useState(false);
  const [LaunchAppModalOpen, setLaunchAppModalOpen] = useState(false);

  const isScroll = () => {
    scroll.current = window.pageYOffset;
    if (scroll.current > 50) setScrollY(false);
    else setScrollY(true);
  };

  const clickHandle = (e) => {
    !isTablet && setActiveMenu(!activeMenu);
    !isTablet && setScrollLock(!scrollLock);

    document.body.classList.add("overflow-hidden");
    if (activeMenu) document.body.classList.remove("overflow-hidden");

    if (e.target.classList.contains("menu-item")) {
      setScrollDirection("down");
      document.body.classList.remove("overflow-hidden");
    }

    !isTablet &&
      setTimeout(() => {
        setScrollLock(!scrollLock);
      }, 700);
  };

  const LaunchAppModalHandle = () => setLaunchAppModalOpen(!LaunchAppModalOpen);

  useEffect(() => {
    isScroll();
    window.addEventListener("scroll", isScroll);
    return () => {
      window.removeEventListener("scroll", isScroll);
    };
  }, []);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
      ) {
        !scrollLock && setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    if (!isTablet) window.addEventListener("scroll", updateScrollDirection);
    return () => {
      if (!isTablet)
        window.removeEventListener("scroll", updateScrollDirection);
    };
  }, [scrollDirection, scrollLock, isTablet]);

  return (
    <>
      <header
        className={`${
          (scrollDirection === "down") & !isTablet ? "-top-36" : "top-0"
        } ${
          !scrollY ? "fixed-header xl:h-[130px]" : "xl:h-[237px]"
        } fixed w-full top-0 left-0 z-40 duration-300 after:absolute after:left-0 after:top-0 after:w-full after:duration-300 after:-z-10 before:absolute before:left-0 before:top-0 before:w-full before:duration-500 before:-z-10 h-auto xl:py-0 py-6`}
      >
        <div className="container flex justify-between items-center h-full sm:flex-row flex-wrap sm:gap-0 gap-4">
          <div className="2xl:w-[368px] xl:w-[332px] w-auto sm:mr-0 mr-auto">
            <a href="/" className="relative z-10">
              <img
                className={`${
                  !scrollY ? "xl:w-[230px]" : "2xl:w-[368px] xl:w-[230px]"
                } w-[120px]`}
                src={Logo}
                alt="Winaged Logo"
              />
            </a>
          </div>
          {!isTablet && (
            <MenuButton
              clickHandle={clickHandle}
              active={activeMenu}
              className="sm:hidden block"
            />
          )}
          <div
            className={`${
              activeMenu ? "open" : ""
            } main-menu min-w-[360px] flex xl:static fixed flex-col justify-between xl:p-0 sm:pt-28 pt-40 pb-10 inset-0 xl:backdrop-blur-none backdrop-blur-xl xl:bg-transparent bg-[rgba(0,0,0,0.9)] ease-in-out`}
            // style={{ transition: !isTablet ? "transform .6s" : "none" }}
          >
            <div className="container font-heading flex xl:flex-row flex-col 2xl:gap-16 xl:gap-6 gap-8 items-start">
              <MenuItem
                href="about"
                scrollY={scrollY}
                clickHandle={clickHandle}
              >
                ABOUT
              </MenuItem>
              <MenuItem
                href="winagedclub"
                scrollY={scrollY}
                clickHandle={clickHandle}
              >
                WINAGED CLUB
              </MenuItem>
              <MenuItem
                href="roadmap"
                scrollY={scrollY}
                clickHandle={clickHandle}
              >
                ROADMAP
              </MenuItem>
              <span
                className={`${
                  !scrollY ? "xl:text-xs" : "2xl:text-base xl:text-xs"
                }  cursor-pointer menu-item hover-eff text-[38px] leading-10 coming-soon relative`}
              >
                PARTNERS
                {ComingSoonPop}
              </span>
            </div>
            {!isTablet && (
              <div className="container">
                <h3 className="text-title-md-m font-heading gradient-text block gradient-4 relative before:h-[2px] before:bg-white before:absolute before:-bottom-2 before:w-16">
                  Social Media
                </h3>
                <span className="h-[1px] gradient-5 block mt-2 mb-4 w-48"></span>
                <SocialMedia gap="gap-8" size="2xl:w-[32px] w-[30px]" />
              </div>
            )}
          </div>
          <div
            className={`${
              !scrollY
                ? "xl:w-[332px] duration-300"
                : "xl:w-[332px] 2xl:w-[368px]"
            } sm:w-auto flex w-full xl:ml-0 ml-auto relative z-10`}
          >
            <div className="coming-soon w-full sm:w-auto relative">
              <Button
                className={`${
                  !scrollY ? "" : "2xl:w-[174px] 2xl:h-[59px] "
                } gradient-2 after:gradient-2-hover text-dark-jungle-green`}
              >
                WHITE PAPER
              </Button>
              {ComingSoonPop}
            </div>
            <Button
              className={`${
                !scrollY ? "" : "2xl:w-[174px]  2xl:h-[59px]"
              } gradient-3 after:gradient-3-hover text-white ml-5`}
              onClick={LaunchAppModalHandle}
            >
              LAUNCH APP
            </Button>
          </div>
          {!isTablet && (
            <MenuButton
              clickHandle={clickHandle}
              active={activeMenu}
              className="sm:block hidden"
            />
          )}
        </div>
      </header>
      <Modal modalClose={LaunchAppModalHandle} status={LaunchAppModalOpen}>
        <h2 className="lg:text-title-lg-m text-title-sm gradient-text gradient-4 font-heading">
          Winaged Ecosystem is <br />
          about to be live!
        </h2>
        <p className="lg:text-sm text-sm-m lg:mt-8 mt-6">
          Soon you will be able to connect your wallet, shop, age your favorite
          wine collections and earn premium awards and advantages.
        </p>
        <p className="lg:text-lg text-lg-m lg:mt-10 mt-8 font-heading">
          Stay tuned!
        </p>
      </Modal>
    </>
  );
};

export default Header;
