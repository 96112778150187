import { forwardRef } from "react";
import { useInView } from "react-intersection-observer";
import Logo from "../../assets/images/logo.svg";
import Powered from "../../assets/images/powered-by-covenlabs.png";
import SocialMedia from "../../components/SocialMedia";
import "./Roadmap.scss";

const RoadItem = forwardRef(
  ({ num, text, xVal, yVal, right, className }, ref) => {
    return (
      <>
        <div
          ref={ref}
          className={`flex flex-col absolute ${xVal} ${yVal} 2xl:w-[360px] lg:w-[220px] ${
            num === "1" ? "w-[131px]" : "w-[123px]"
          } animated ${className} roadmap-num opacity-0`}
        >
          <span
            className={`${
              right ? "lg:col-right" : ""
            } font-heading text-[70px] gradient-text gradient-3 relative leading-[70px]`}
          >
            {num}
          </span>
          <h4
            className={`${
              right ? "lg:text-right" : ""
            } text-dark-jungle-green lg:text-sm text-sm-m font-medium mt-2`}
          >
            {text}
          </h4>
        </div>
      </>
    );
  }
);

const Roadmap = () => {
  const title = useInView({ triggerOnce: true });
  const description1 = useInView({ triggerOnce: true });
  const description2 = useInView({ triggerOnce: true });
  const roadItem1 = useInView({ triggerOnce: true });
  const roadItem2 = useInView({ triggerOnce: true });
  const roadItem3 = useInView({ triggerOnce: true });
  const roadItem4 = useInView({ triggerOnce: true });
  const roadItem5 = useInView({ triggerOnce: true });
  const roadItem6 = useInView({ triggerOnce: true });
  const roadItem7 = useInView({ triggerOnce: true });
  const roadItem8 = useInView({ triggerOnce: true });

  return (
    <section className="roadmap -mt-16">
      <div className="roadmap-1 relative z-10 xl:h-[2437px] lg:h-[1760px] h-[888px]">
        <div className="xl:container h-full lg:max-w-2xl max-w-[313px] w-full mx-auto flex flex-col justify-center items-center xl:pt-[580px] lg:pt-[340px] pt-[270px]">
          <h2
            ref={title.ref}
            className={`${
              title.inView && "animated fadeInUp"
            } font-heading xl:text-title-lg lg:text-[52px] lg:leading-[54px] text-title-md-m text-center gradient-text gradient-4 opacity-0`}
          >
            Decentralized Winomous <br />
            Organization
          </h2>
          <div className="max-w-4xl mx-auto">
            <p
              ref={description1.ref}
              className={`${
                description1.inView && "animated fadeInUp "
              } lg:mt-10 mt-4 lg:text-lg text-md-m gradient-text gradient-4 text-center opacity-0`}
            >
              Any wine NFT owner will be able to vote and give proposals for
              their own brand, and after a certain period of time, they will
              have a say in the vital decisions of the project.
            </p>
            <p
              ref={description2.ref}
              className={`${
                description2.inView && "animated fadeInUp"
              } lg:mt-6 mt-3 lg:text-lg text-md-m gradient-text gradient-4 text-center opacity-0`}
            >
              Master NFT owners, which we will introduce in the later stages of
              our roadmap, will be able to have a say in many areas!
            </p>
          </div>
        </div>
      </div>
      <div
        id="roadmap"
        className="roadmap-2 relative z-10 lg:h-[1958px] h-[900px] pt-40 lg:-mt-[300px] -mt-[180px]"
      >
        <div className="relative container w-full h-full flex flex-col items-center justify-between">
          <h2 className="font-heading lg:text-title-lg text-title-lg-m text-center gradient-text gradient-3 w-full">
            roadmap
          </h2>
          <RoadItem
            ref={roadItem1.ref}
            xVal="lg:top-[280px] top-[70px]"
            yVal="2xl:left-[160px] lg:left-[calc(50%-490px)] left-[calc(50%-166px)]"
            num="1"
            text="Community Building & Marketing Campaigns"
            className={`${roadItem1.inView && "fadeInRight"}`}
          />
          <RoadItem
            ref={roadItem2.ref}
            xVal="lg:top-[420px] top-[70px]"
            yVal="2xl:left-[910px] lg:left-[calc(50%--120px)] left-[calc(50%--60px)]"
            num="2"
            text="Private Funding Tour"
            right={true}
            className={`${roadItem2.inView && "fadeInLeft"}`}
          />
          <RoadItem
            ref={roadItem3.ref}
            xVal="lg:top-[535px] top-[207px]"
            yVal="2xl:left-[90px] lg:left-[calc(50%-470px)] left-[calc(50%-166px)]"
            num="3"
            text="Expansion of Partnerships"
            className={`${roadItem3.inView && "fadeInRight"}`}
          />
          <RoadItem
            ref={roadItem4.ref}
            xVal="lg:top-[647px] top-[207px]"
            yVal="2xl:left-[1127px] lg:left-[calc(50%--266px)] left-[calc(50%--60px)]"
            num="4"
            text="Establishment of Marketplace"
            right={true}
            className={`${roadItem4.inView && "fadeInLeft"}`}
          />
          <RoadItem
            ref={roadItem5.ref}
            xVal="lg:top-[752px] top-[362px]"
            yVal="2xl:left-[151px] lg:left-[calc(50%-461px)] left-[calc(50%-166px)]"
            num="5"
            text="Public Funding Tour"
            className={`${roadItem5.inView && "fadeInRight"}`}
          />
          <RoadItem
            ref={roadItem6.ref}
            xVal="lg:top-[860px] top-[362px]"
            yVal="2xl:left-[1140px] lg:left-[calc(50%--150px)] left-[calc(50%--60px)]"
            num="6"
            text="Releasing the first NFT Collections"
            right={true}
            className={`${roadItem6.inView && "fadeInLeft"}`}
          />
          <RoadItem
            ref={roadItem7.ref}
            xVal="lg:top-[972px] top-[521px]"
            yVal="2xl:left-[121px] lg:left-[calc(50%-441px)] left-[calc(50%-166px)]"
            num="7"
            text="Token Generation Event"
            className={`${roadItem7.inView && "fadeInRight"}`}
          />
          <RoadItem
            ref={roadItem8.ref}
            xVal="lg:top-[1078px] top-[521px]"
            yVal="2xl:left-[1120px] lg:left-[calc(50%--250px)] left-[calc(50%--60px)]"
            num="8"
            text="Activating the Staking Options"
            right={true}
            className={`${roadItem8.inView && "fadeInLeft"}`}
          />
          <div className="relative w-full max-w-[520px] flex flex-col items-center lg:pb-20 pb-2 before:absolute lg:before:w-[815px] before:w-[247px] lg:before:h-80 before:h-[100px] lg:before:-left-36 before:left-[calc(50%-125px)] lg:before:-top-24 before:-top-8 before:-z-10 before:opacity-50 before:bg-stone-900 before:blur-[130px]">
            <img src={Logo} alt="" className="lg:w-[267px] w-[107px]" />
            <hr className="w-full lg:my-6 my-1 opacity-20" />
            <SocialMedia
              gap="2xl:gap-12 lg:gap-7 gap-5"
              size="2xl:w-[32px] lg:w-[30px] lg:h-[30px] w-[14px] h-[14px]"
            />
            <img
              src={Powered}
              alt="Powered by CovenLabs"
              className="lg:w-[190px] w-[100px] lg:mt-8 mt-2"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
