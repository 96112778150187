import { ReactComponent as BannerText } from "../../assets/images/banner-text.svg";
import BackgroundVideo from "../../assets/videos/bgmovie.mp4";
import Glass from "../../assets/images/glass.png";
import "./Intro.scss";
import { useEffect, useRef, useState } from "react";

const Intro = () => {
  const [final, setFinal] = useState(false);
  const refScrollY = useRef();
  const introWrap = useRef();
  const scrollHandle = () => {
    refScrollY.current = window.scrollY;

    if (refScrollY.current > introWrap.current.clientHeight / 1.5) {
      setFinal(true);
    } else {
      setFinal(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", () => scrollHandle());

    return window.removeEventListener("scroll", () => scrollHandle());
  }, [refScrollY]);

  return (
    <section
      ref={introWrap}
      className="w-full h-screen xl:max-h-[970px] max-h-[665px] fixed -z-10 top-0 overflow-hidden"
    >
      <div className="container h-full flex justify-center items-center xl:pb-9">
        <BannerText
          className={`${final ? "fadeOutDown" : "fadeInUp"} animated`}
        />
      </div>
      <div
        className={`${
          final ? "fadeOutDown" : "fadeInUp"
        } animated absolute xl:top-[calc(50%-235px)] top-[calc(50%-152px)] xl:left-[calc(50%-200px)] left-[calc(50%-150px)] z-10`}
      >
        <img
          className="glass xl:w-auto w-[320px] slideInUp animated"
          src={Glass}
          alt="Winaged is a revolution"
          srcSet={`${require(`../../assets/images/glass.png`)} 1x, ${require(`../../assets/images/glass@2x.png`)} 2x`}
        />
      </div>
      <video
        playsInline
        src={BackgroundVideo}
        muted
        autoPlay
        loop
        className="absolute top-0 left-0 w-full h-full object-cover -z-10"
      />
    </section>
  );
};

export default Intro;
