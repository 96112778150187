import { useInView } from "react-intersection-observer";
import { useParallax } from "react-scroll-parallax";
import Bottle from "../../assets/images/winebottle.png";
import "./UtiltyOfNft.scss";

const UtilityOfNft = () => {
  const glassParallax = useParallax({
    speed: 12,
  });
  const glassText1 = useInView({ triggerOnce: true });
  const glassText2 = useInView({ triggerOnce: true });
  const glassText3 = useInView({ triggerOnce: true });
  const glassText4 = useInView({ triggerOnce: true });
  const glassText5 = useInView({ triggerOnce: true });
  const glassText6 = useInView({ triggerOnce: true });
  const glassText7 = useInView({ triggerOnce: true });
  const glassText8 = useInView({ triggerOnce: true });
  const glassText9 = useInView({ triggerOnce: true });

  return (
    <>
      <section className="stake lg:pt-40 pt-28 lg:pb-20 pb-6 relative">
        <div className="container">
          <div className="xl:grid grid-cols-2 relative">
            <div className={`col-span-1 relative z-10`}>
              <div className="grid xl:grid-cols-1 grid-cols-3">
                <div className="col-span-2">
                  <h2
                    className={`${
                      glassText1.inView && "animated fadeInLeft"
                    } lg:text-title-lg text-title-lg-m font-heading opacity-0`}
                    ref={glassText1.ref}
                  >
                    stake, age, earn.
                  </h2>
                  <h3
                    className={`${
                      glassText2.inView && "animated fadeInLeft"
                    } lg:text-title-sm text-title-sm-m font-heading opacity-0 lg:mt-0 mt-3`}
                    ref={glassText2.ref}
                  >
                    The Main Utility of NFT
                  </h3>
                  <p
                    className={`${
                      glassText3.inView && "animated fadeInLeft"
                    } lg:text-lg text-lg-m lg:mt-9 mt-6 opacity-0`}
                    ref={glassText3.ref}
                  >
                    Winaged creates an ecosystem where wineries have their own
                    community under the Winaged Club. Brands’ consumers can
                    become members of this club by purchasing Wine NFTs. Through
                    their memberships, they will be able to age their wine and
                    store it in the vineyard’s perfectly temperature monitored
                    cellars.
                  </p>
                  <p
                    className={`${
                      glassText4.inView && "animated fadeInLeft"
                    } lg:text-lg text-lg-m lg:mt-6 mt-3 opacity-0`}
                    ref={glassText4.ref}
                  >
                    We have moved the aging concept to a decentralized
                    environment for you!
                  </p>
                </div>
              </div>
              <h3
                className={`${
                  glassText5.inView && "animated fadeInLeft"
                } text-title-sm font-heading lg:mt-16 mt-8 opacity-0`}
                ref={glassText5.ref}
              >
                The process is simple
              </h3>
              <div className="lg:mt-9 mt-6 stake-list xl:max-w-[576px] w-full text-dark-jungle-green">
                <div
                  className={`${
                    glassText6.inView && "animated fadeInUp"
                  } stake-list-item backdrop-blur-lg rounded-[36px] gradient-2 flex items-center lg:px-11 px-6 lg:py-7 py-5 opacity-0`}
                  ref={glassText6.ref}
                >
                  <div className="stake-icon lg:mr-12 mr-4">
                    <span></span>
                  </div>
                  <span className="lg:text-md text-md-m">
                    Connect your wallet by going to the website.
                  </span>
                </div>
                <div
                  className={`${
                    glassText7.inView && "animated fadeInUp"
                  } stake-list-item backdrop-blur-lg rounded-[36px] gradient-2 flex items-center lg:px-11 px-6 lg:py-7 py-5 lg:mt-5 mt-3 opacity-0`}
                  ref={glassText7.ref}
                >
                  <div className="stake-icon lg:mr-12 mr-4">
                    <span></span>
                    <span></span>
                  </div>
                  <span className="lg:text-md text-md-m">
                    Go to the Staking section.
                  </span>
                </div>
                <div
                  className={`${
                    glassText8.inView && "animated fadeInUp"
                  } stake-list-item backdrop-blur-lg rounded-[36px] gradient-2 flex items-center lg:px-11 px-6 lg:py-7 py-5 lg:mt-5 mt-3 opacity-0`}
                  ref={glassText8.ref}
                >
                  <div className="stake-icon lg:mr-12 mr-4">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <span className="lg:text-md text-md-m">
                    Lock your wine NFT to the protocol within 1-3-5 years and
                    start earning!
                  </span>
                </div>
              </div>
              <p
                className={`${
                  glassText9.inView && "animated fadeInLeft"
                } lg:text-sm text-lg-m lg:mt-16 mt-6 opacity-0`}
                ref={glassText9.ref}
              >
                Once you stake your NFT to the protocol, partner Winery will
                store your wine in their wine cellar. What’s best is that you
                will be able to monitor your wine whenever, wherever you want!
              </p>
            </div>
            <img
              className="absolute max-w-none xl:w-auto lg:w-[900px] w-[580px] lg:-right-44 sm:-right-40 -right-48 xl:top-[-388px] lg:top-[-229px] -top-28"
              src={Bottle}
              ref={glassParallax.ref}
              alt="Once you stake your NFT to the protocol, partner Winery will
                store your wine in their wine cellar."
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default UtilityOfNft;
