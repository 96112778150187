import { useRef } from "react";
import "./Modal.scss";

const Modal = ({ status, modalClose, children }) => {
  const modalRef = useRef();

  const clickHandle = () => {
    modalRef.current.classList.add("zoomOut");
    setTimeout(() => {
      modalClose();
    }, 600);
  };

  const displayHandle = (e) => {
    if (e.target.closest(".modal-container") === null) clickHandle();
  };

  return (
    <div
      onClick={displayHandle}
      ref={modalRef}
      className={`${
        status ? "flex animated" : "hidden"
      } modal fixed inset-0 backdrop-blur-sm z-50 flex justify-center items-center p-4`}
    >
      <div className="modal-container relative bg-old-brick/95 lg:rounded-[54px] rounded-3xl border-[1px] border-[white]/30 max-w-[640px] text-center lg:px-8 px-6 lg:py-12 py-8 zoomIn animated">
        <span onClick={clickHandle} className={`modal-close`}>
          <span></span>
          <span></span>
        </span>
        {children}
      </div>
    </div>
  );
};

export default Modal;
