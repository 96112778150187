import { ReactComponent as Twitter } from "../assets/images/ic-twitter.svg";
import { ReactComponent as Instagram } from "../assets/images/ic-instagram.svg";
import { ReactComponent as Linkedin } from "../assets/images/ic-linkedin.svg";
// import { ReactComponent as Discord } from "../assets/images/ic-discord.svg";
// import { ReactComponent as Telegram } from "../assets/images/ic-telegram.svg";
import { ReactComponent as Medium } from "../assets/images/ic-medium.svg";
import { ReactComponent as Facebook } from "../assets/images/ic-facebook.svg";

const SocialMedia = ({ gap, size }) => {
  return (
    <>
      <div className={`${gap} flex`}>
        <a
          className="relative top-0 hover:top-[-4px] duration-300"
          href="https://twitter.com/winaged"
          alt="Winaged Twitter"
          target="_blank"
          rel="noreferrer"
        >
          <Twitter className={size} />
        </a>
        <a
          className="relative top-0 hover:text-[#fb3958] hover:top-[-4px] duration-300"
          href="https://www.instagram.com/winaged"
          alt="Winaged Instagram"
          target="_blank"
          rel="noreferrer"
        >
          <Instagram className={size} />
        </a>
        <a
          className="relative top-0 hover:text-[#0077b5] hover:top-[-4px] duration-300"
          href="https://www.linkedin.com/company/winaged"
          alt="Winaged Linkedin"
          target="_blank"
          rel="noreferrer"
        >
          <Linkedin className={size} />
        </a>
        <a
          className="relative top-0 hover:text-[#1a77f2] hover:top-[-4px] duration-300"
          href="http://www.facebook.com/groups/551193560284029"
          alt="Winaged Facebook"
          target="_blank"
          rel="noreferrer"
        >
          <Facebook className={size} />
        </a>
        {/* <a
          className="relative top-0 hover:text-[#7289d9] hover:top-[-4px] duration-300"
          href="discord.com"
          alt="Winaged Discord"
          target="_blank" rel="noreferrer"
        >
          <Discord className={size} />
        </a> */}
        {/* <a
          className="relative top-0 hover:text-[#0088cc] hover:top-[-4px] duration-300"
          href="https://t.me/#"
          alt="Winaged Telegram"
          target="_blank"
          rel="noreferrer"
        >
          <Telegram className={size} />
        </a> */}
        <a
          className="relative top-0 hover:text-[#66cdaa] hover:top-[-4px] duration-300"
          href="https://medium.com/@info_37685"
          alt="Winaged Medium"
          target="_blank"
          rel="noreferrer"
        >
          <Medium className={size} />
        </a>
      </div>
    </>
  );
};

export default SocialMedia;
