import { useInView } from "react-intersection-observer";
import { useParallax } from "react-scroll-parallax";
import Avax from "../assets/images/avax.png";

const WineOffering = () => {
  const avaxParallax = useParallax({
    speed: 12,
  });

  const title = useInView({ triggerOnce: true });
  const description1 = useInView({ triggerOnce: true });
  const description2 = useInView({ triggerOnce: true });

  return (
    <section className="-mt-20">
      <div className="container">
        <div className="grid lg:grid-cols-2 grid-cols-1 lg:text-left text-center">
          <div className="col-span-1">
            <img
              className="balloons lg:w-auto w-60 mx-auto"
              src={Avax}
              alt="IWO with Subnet"
              ref={avaxParallax.ref}
            />
          </div>
          <div className="col-span-1 flex flex-col justify-center">
            <div
              className={`${
                title.inView && "animated opacity-0 minFadeLeft"
              } relative`}
              ref={title.ref}
            >
              <h2 className="xl:text-title-lg lg:text-[84px] lg:leading-[90px] text-title-lg-m gradient-text gradient-1 font-heading lg:mt-0 -mt-10">
                IWO <br />
                with Subnet
              </h2>
              {/* <span className="lg:text-title-sm text-title-sm-m lg:leading-9 font-heading text-mountain-mist opacity-50 lg:absolute left-56 top-1">
                (Initial Wine <br className="lg:block hidden" />
                Offering)
              </span> */}
            </div>
            <div
              ref={description1.ref}
              className={`${
                description1.inView && "animated opacity-0 minFadeLeft"
              }`}
            >
              <p className="text-mountain-mist lg:text-md text-md-m lg:mt-6 mt-3">
                Winaged is an ecosystem that transforms Turkish wine into
                blockchain-based assets with Initial Wine Offering (IWO);
                unlocks aging, storage, investing, and earning through the
                conversion of these wine collections into NFTs.
              </p>
            </div>
            <div
              ref={description2.ref}
              className={`${
                description2.inView && "animated opacity-0 minFadeLeft"
              }`}
            >
              <p className="text-mountain-mist lg:text-md text-md-m lg:mt-6 mt-3">
                Winaged NFTs represent wine assets (bottles of wine) that are
                stored in optimal conditions. Our NFT assets will make it
                tradable through an NFT marketplace on our own chain that will
                be built on the Avalanche Subnet.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WineOffering;
