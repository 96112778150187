import Header from "./layout/header/Header";
import Intro from "./sections/intro/Intro";
import WhoWeAre from "./sections/whoweare/WhoWeAre";
import UtilityOfNft from "./sections/utiltyofnft/UtiltyOfNft";
import Wine from "./sections/wine/Wine";
import WinagedClub from "./sections/winagedclub/WinagedClub";
import WineOffering from "./sections/WineOffering";
import Newsletter from "./sections/newsletter/Newsletter";
import Roadmap from "./sections/roadmap/Roadmap";

function App() {
  return (
    <>
      <div className="overflow-x-hidden">
        <Header />
        <Intro />
        <WhoWeAre />
        <div className="bg-white">
          <UtilityOfNft />
          <Wine />
          <WineOffering />
          <WinagedClub />
          <Newsletter />
          <Roadmap />
        </div>
      </div>
    </>
  );
}

export default App;
