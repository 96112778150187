import { useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Newsletter.scss";

const Newsletter = () => {
  const emailInputRef = useRef();

  function validateEmail(e) {
    e.preventDefault();
    if (
      // eslint-disable-next-line no-useless-escape
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        emailInputRef.current.value
      )
    ) {
      const response = axios.post(
        "https://website-api.winaged.com/email_newsletter",
        e.target
      );
      toast.promise(response, {
        pending: "Submit Form...",
        success: "Submission Successful!",
        error: "Submission Failed!",
      });

      response.then(() => {
        emailInputRef.current.classList.remove("border-red-600");
      });
      response.catch(() => {
        emailInputRef.current.classList.add("border-red-600");
      });
    } else {
      emailInputRef.current.classList.add("border-red-600");
      toast.error("Email Required!");
    }
  }

  return (
    <section className="newsletter lg:mt-5 relative z-20">
      <div className="container">
        <h2 className="lg:text-title-md text-title-md-m gradient-1 gradient-text font-heading text-center">
          join the <br />
          winaged newsletter
        </h2>
        <h3 className="lg:text-title-sm text-title-sm-m mt-3 text-mountain-mist font-heading text-center">
          get launch updates before everyone
        </h3>
        <form
          onSubmit={validateEmail}
          className="mt-9 relative w-full lg:max-w-[592px] max-w-[342px] lg:h-[92px] h-[52px] mx-auto"
        >
          <input
            name="email"
            ref={emailInputRef}
            type="text"
            placeholder="E-Mail Address"
            className="w-full h-full lg:pr-48 pr-28 lg:pl-8 pl-5 gradient-2 backdrop-blur-sm rounded-[30px] text-dark-jungle-green border-[1px] outline-none lg:text-sm text-xs placeholder:text-dark-jungle-green"
          />
          <button
            type="submit"
            className="newsletter-btn top-0 right-0 absolute lg:w-[178px] w-[104px] h-full lg:text-title-sm text-title-sm-m font-heading lg:rounded-none rounded-full"
          >
            JOIN
          </button>
        </form>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        limit={5}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="dark"
      />
    </section>
  );
};

export default Newsletter;
