import { useInView } from "react-intersection-observer";
import useChangeWidth from "../../hooks/useChangeWidth";
import { useParallax } from "react-scroll-parallax";
import LeafImg from "../../assets/images/who-we-are-bg_m.png";
import "./whoweare.scss";

const WhoWeAre = () => {
  const leafParallax = useParallax({
    speed: 12,
  });
  const leafText1 = useInView({ triggerOnce: true });
  const leafText2 = useInView({ triggerOnce: true });
  const leafText3 = useInView({ triggerOnce: true });
  const isSize = useChangeWidth(1280);

  return (
    <section
      id="about"
      className="who-we-are overflow-hidden xl:mt-[970px] mt-[665px] xl:bg-transparent bg-white"
    >
      <div className="container relative xl:pt-[220px] pt-8 xl:pb-[220px] pb-12">
        <div className="xl:grid grid-cols-2 gap-4 xl:flex-row flex-col-reverse flex">
          <div className="col-span-1">
            <h2
              className={`${
                leafText1.inView && "animated fadeInLeft"
              } opacity-0 font-heading lg:text-title-lg text-title-lg-m gradient-1 gradient-text`}
              ref={leafText1.ref}
            >
              who we are?
            </h2>
            <h3
              className={`${
                leafText2.inView && "animated fadeInLeft"
              } opacity-0 lg:text-xl text-xl-m lg:mt-9 mt-6 text-dark-jungle-green`}
              ref={leafText2.ref}
            >
              <strong>Winaged</strong> is a revolution that allows Turkish wine
              producers to meet Web3 world and introduce their high quality wine
              and vineyards to a global community.
            </h3>
            <h3
              className={`${
                leafText3.inView && "animated fadeInLeft"
              } opacity-0 lg:text-xl text-xl-m lg:mt-9 mt-6 text-dark-jungle-green`}
              ref={leafText3.ref}
            >
              Through Winaged, vineries will be able to offer custodial service
              to day to day users to hold their aging wine as a future
              investment. Moreover, become an exclusive member of a wine club
              that is powered by CovenLabs.
            </h3>
          </div>
          <div className="col-span-1 flex xl:justify-end">
            <span
              ref={leafParallax.ref}
              className="2xl:mr-0 -mr-12 2xl:-mt-28 mt-10 absolute who-we-are-bg xl:block hidden"
            />
            {!isSize ? <img src={LeafImg} alt="" className="" /> : ""}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
