import { useCallback, useEffect, useState } from "react";

const useChangeWidth = (minwidth) => {
  const [isMobile, setIsMobile] = useState();

  const handleResize = useCallback((e) => {
    if (e.matches) setIsMobile(true);
    else setIsMobile(false);
  }, []);

  useEffect(() => {
    const minWidthMatch = window.matchMedia(`(min-width: ${minwidth}px)`);
    minWidthMatch.addEventListener("change", handleResize);
    setIsMobile(minWidthMatch.matches);
    return () => {
      minWidthMatch.removeEventListener("change", handleResize);
    };
  }, [handleResize, minwidth]);

  return isMobile;
};

export default useChangeWidth;
